// Any plugins you want to use has to be imported
// Deatil plugins list see https://www.tiny.cloud/docs/plugins/

const plugins = [
  `advlist autolink lists link image charmap print preview anchor,
  searchreplace visualblocks code fullscreen,
  insertdatetime media table  help wordcount image  axupimgs`
]

export default plugins
