<template>
  <editor
    v-if="status === 'success'"
    v-model="myValue"
    :init="init"
  >
  </editor>
  <!--  apiKey的使用方法  -->
  <!--  <editor-->
  <!--    v-model="myValue"-->
  <!--    :init="init"-->
  <!--    :api-key="apiKey"-->
  <!--  >-->
  <!--  </editor>-->

</template>
<script>
import Editor from "@tinymce/tinymce-vue"
import {uploadFile} from "@/request";
import config from "@/config";
import plugins from './plugins'
import toolbar from './toolbar'

const defaultConfig = {
  width: 1000,
  height: 600,
  menubar: true,
  language: 'zh_CN'
}
export default {
  components: {
    Editor
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  config: {
    type: Object,
    default: () => {
      return {
        width: 1000,
        height: 600,
        menubar: true,
        language: 'zh_CN'
      }
    }
  }
  },
  data() {
    return {
      myValue: this.value,
      apiKey: "在TinyMCE中申请的apiKey",//使用本地加载js的话可以不用apiKey'
      init: {
        plugins,
        toolbar,
        convert_urls: false,// 上传图片文件路径变为相对路径的解决方法
        removed_menuitems: 'media',
        width: Object.assign(defaultConfig, this.config).width,
        height: Object.assign(defaultConfig, this.config).height,
        menubar: Object.assign(defaultConfig, this.config).menubar,
        language: Object.assign(defaultConfig, this.config).language,
        language_url: "/tinymce/langs/zh_CN.js",// 汉化包
        resize: 'both',
        // 自定义图片上传
        images_upload_handler: async (blobInfo, success, failure) => {
          try {
            let formData = new FormData();
            formData.append("file", blobInfo.blob())
            formData.append("type", 1);
            let res = await uploadFile(formData);
            let url = `${config.downloadUrl}${res.url}`
            success(url)
          } catch (err) {
            console.log(err)
          }
        },
      },
      // tinymce组件script
      tinyScript: null,
      status: 'pending'
    }
  },

  watch: {
    value(newValue) {
      this.myValue = newValue;
    },
    myValue(newValue) {
      this.$emit("input", newValue);
    },
  },
  created() {
    this.initTinyMceScript().then(() => {
      tinymce.init({})
      this.status = 'success'
    })
  },
  methods: {
    initTinyMceScript() {
      return new Promise(resolve => {
        if (this.tinyScript) {
          return resolve(null)
        }
        this.tinyScript = document.createElement("script");
        this.tinyScript.type = "text/javascript";
        this.tinyScript.src = "/tinymce/tinymce.min.js";
        document.body.appendChild(this.tinyScript);
        this.tinyScript.onload = () => {
          resolve(null)
        }
      })
    },
    desTinyMceScript() {
      if (this.tinyScript) {
        document.body.removeChild(this.tinyScript)
        this.tinyScript = null
      }
    }
  },
  beforeDestroy() {
    this.desTinyMceScript()
  }
}
</script>

